<template>
  <div class="graduation_student_list_page">
    <myTable title="毕业学员列表" :list="list" :columns="columns">
      <template slot="top_block">
        <div class="code_block">
          <el-select v-model="selectValue" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="keyWord"
            style="width: auto; margin-left: 0.2rem"
            type="text"
            placeholder="请输入学生姓名搜索"
          ></el-input>
          <input
            style="display:block;width:0px;height:0px;opacity:0;"
            placeholder=""
            resize="none"
          />
          <el-button @click="search" class="table_top_btn_default"
            >搜索</el-button
          >
        </div>
      </template>
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleEdit(item.data.row, 'view')">
            查看
          </div>
          <div class="table_btn" @click="handleBack(item.data.row)">恢复</div>
        </div>
      </template>
    </myTable>
    <comPagination :params="params" @changePage="changePage"></comPagination>
    <comdialog
      :list="fromList"
      ref="comdialog"
      :params.sync="stuInfo"
      :edit="alertStatus"
      title="查看学生"
      @onSubmit="onSubmit"
      @close="comdialogClose"
    ></comdialog>
  </div>
</template>

<script>
import comdialog from "@/components/admin/editDialog.vue";
import myTable from "@/components/admin/table.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "graduationStudentList",
  components: {
    comdialog,
    comPagination,
    myTable,
  },
  data() {
    return {
      selectValue: "",
      options: [
        {
          label: "2021年",
          value: 1,
        },
      ],
      columns: [
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sex",
          label: "性别",
        },
        {
          prop: "gradeName",
          label: "班级",
        },
        {
          prop: "integral",
          label: "积分",
        },
      ],
      list: [],
      keyWord: "",
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
      alertStatus: "view",
      fromList: [
        {
          prop: "name",
          type: "text",
          label: "学生姓名",
          required: true,
        },
        {
          prop: "idCardType",
          type: "radio",
          label: "证件类型",
          required: false,
          options: [
            {
              label: "身份证",
              value: 0,
            },
            {
              label: "护照",
              value: 1,
            },
            // {
            //   label: '其他证件',
            //   value: 2,
            // },
          ],
        },
        {
          prop: "idCard",
          type: "text",
          label: "证件号",
          required: false,
        },
        {
          prop: "parentName",
          type: "text",
          label: "家长姓名",
          required: false,
        },
        {
          prop: "photo",
          type: "upload",
          label: "学员照片",
          required: false,
        },
        {
          prop: "birthday",
          type: "date",
          label: "学员出生日期",
          required: true,
        },
        {
          prop: "gradeId",
          type: "select",
          label: "学员所属班级",
          required: true,
          options: [],
        },
        {
          prop: "sex",
          type: "radio",
          label: "学员性别",
          required: true,
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        {
          prop: "phone",
          type: "number",
          label: "家长联系电话",
          required: false,
        },
      ],
      stuInfo: {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: 3,
        sex: "",
        phone: "",
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getInfo();
    },
    search() {
      this.params.page = 1;
      this.params.size = 10;
      this.getInfo();
    },
    handleEdit(val, type) {
      console.log(val);
      this.$refs.comdialog.isOpen();
      this.alertStatus = type;
      if (type == "view") {
        this.stuInfo = {
          id: val.id,
          name: val.name,
          idCardType:
            val.idCardType == "0"
              ? "身份证"
              : val.idCardType == "1"
              ? "护照"
              : "其他证件",
          idCard: val.idCard,
          parentName: val.parentName,
          photo: val.photo ? this.constant.URL_admin + val.photo : "",
          birthday: val.birthday,
          gradeId: val.gradeName,
          // sex: val.sex == "1" ? "男" : "女",
          sex: val.sex,
          phone: val.phone,
        };
      }
    },
    //关闭编辑弹窗
    comdialogClose() {
      console.log(this.stuInfo, this.$refs.comdialog);
    },
    handleBack(val) {
      console.log(val);
      let data = {
        id: val.id,
      };
      this.api.admin.coveryGraduation(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "恢复成功",
          }).isShow();
          this.search();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    getInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        name: this.keyWord,
        type: 2,
      };
      this.list = [];
      this.api.admin.findChangeStuList(this.params, data).then((res) => {
        this.list = res.data.list.map((item) => {
          console.log(item,'dddddd')
          return {
            ...item,
            sex: item.sex == "1" ? "男" : "女",
          };
        });
        console.log(this.list,'this.list')
        this.params.total = res.data.total;
      });
    },
    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getInfo();
    },
  },
};
</script>

<style lang="scss">
.graduation_student_list_page {
  .code_block {
    @include flex(row, flex-end, center);

    .el-input__inner {
      height: 60px;

      width: 250px;
      font-size: 20px;
    }
  }
}
</style>